@font-face {
    font-family: "CamptonBook";
    src: url("/fonts/CamptonBook/font.woff");
}

@font-face {
    font-family: "CamptonBold";
    src: url("/fonts/CamptonBold/font.woff");
}

@font-face {
    font-family: "CamptonSemiBold";
    src: url("/fonts/CamptonSemiBold/font.woff");
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: CamptonBook, sans-serif;
    height: 100vh;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    font-family: CamptonBook, sans-serif;
}
